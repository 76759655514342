<template>
  <v-expansion-panels accordion>
    <v-expansion-panel class="rounded-lg errorBackround">
      <v-expansion-panel-header>
        <div class="d-flex justify-space-between align-center">
          <p class="error--text mb-0">{{ title }}</p>
          <v-btn
            v-if="$vuetify.breakpoint.smAndUp"
            color="white"
            class="black--text text-capitalize mr-4"
            small
            @click="$emit('btnClick')"
          >
            {{ btnText }}
          </v-btn>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <p class="error--text mb-0">{{ text }}</p>
        <v-btn
          v-if="!$vuetify.breakpoint.smAndUp"
          color="white"
          class="black--text text-capitalize mt-3"
          small
          block
          @click="$emit('btnClick')"
        >
          {{ btnText }}
        </v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'NotificationCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    btnText: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel {
  &:before {
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0 !important;
  }
}
</style>
